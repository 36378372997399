import axios from 'axios'
import { addOrUpdateItems, processEntities, resolveIdAndParams } from '../helpers'
import * as types from '../mutation-types'
export const state = {
  items: [],
}
export const getters = {
  all: state => state.items,
  byId: state => id => state.items.find(item => item.id === id),
  byIds: state => ids => state.items.filter(item => ids.includes(item.id)),
}
export const mutations = {
  [types.BATCH_ADD_OR_UPDATE]: (state, items) => addOrUpdateItems(state, items),
}
export const actions = {
  fetchById(store, param) {
    const [id, params] = resolveIdAndParams(param)
    return axios.get(`api/sales/orders/${id}`, { params }).then(res => {
      processEntities(res.data.data, 'orders')
      return res.data
    })
  },
  update({ dispatch }, { orderId, userId, data }) {
    return axios.put(`api/sales/orders/${orderId}`, data).then(() => {
      dispatch('fetchPostCutoffChangesEligible', userId)
      dispatch('fetchUnsuspendPostCutoffEligibleOrder', {userId})
    })
  },
  fetchPostCutoffChangesEligible(store, userId) {
    return axios
      .get(
        `api/sales/admin/post-cutoff-changes/eligible-orders?user_id=${userId}&include[]=post_cutoff_activities&include[]=cart`
      )
      .then(res => {
        processEntities(res.data.data, 'orders')
        return res.data
      })
  },
  fetchUnsuspendPostCutoffEligibleOrder(store, {userId}) {
    return axios
      .get(`api/sales/admin/post-cutoff-changes/${userId}/unsuspend-post-cutoff-eligible-order`, {
        params: { include: ['post_cutoff_activities', 'cart', 'cart.items', 'suspendedOrderActivation', 'payments'] }
      })
      .then(res => {
        processEntities(res.data.data, 'orders')
        return res.data
      })
  },
  postCutoffSkip({ dispatch }, { orderId, userId, data }) {
    return axios
      .post(`api/sales/admin/post-cutoff-changes/skipped-orders/${orderId}`, data)
      .then(() => {
        dispatch('fetchPostCutoffChangesEligible', userId)
      })
  },
  postCutoffUnskip({ dispatch }, { orderId, userId, data }) {
    return axios
      .post(`api/sales/admin/post-cutoff-changes/unskipped-orders/${orderId}`, data)
      .then(() => {
        dispatch('fetchPostCutoffChangesEligible', userId)
      })
  },
  unsuspendPostCutoffEligibleOrder({ dispatch }, {userId, orderId, data}) {
    return axios
      .patch(`api/sales/admin/post-cutoff-changes/${orderId}/unsuspend-post-cutoff-order`, data)
      .then((res) => {
        dispatch('fetchUnsuspendPostCutoffEligibleOrder', {userId})
        dispatch('fetchById', { id: orderId, params: { include: 'cart' } })

        return res.data
      })
  },
  collectSuspendedOrderPayment({ dispatch }, {orderId, userId}) {
    return axios
      .post(`api/billing/admin/collect-suspended-order-payment/${orderId}`)
      .then((res) => {
        dispatch('fetchUnsuspendPostCutoffEligibleOrder', userId)
        dispatch('fetchById', { id: orderId, params: { include: 'cart' } })

        return res.data
      })
  },
  unlockPostCutoffChanges({ dispatch }, { orderId, userId }) {
    return axios
      .post(`api/sales/admin/post-cutoff-changes/unlocked-post-cutoff-orders/${orderId}`)
      .then(() => {
        dispatch('fetchPostCutoffChangesEligible', userId)
      })
  },
  lockPostCutoffChanges({ dispatch }, { orderId, userId }) {
    return axios
      .delete(`api/sales/admin/post-cutoff-changes/unlocked-post-cutoff-orders/${orderId}`)
      .then(() => {
        dispatch('fetchPostCutoffChangesEligible', userId)
      })
  },
  creatableOrders(store, param) {
    const [id, params] = resolveIdAndParams(param)
    return axios.get(`/api/customers/admin/users/${id}/orders/creatable-orders`);
  },
  createOrder(store, param) {
    const [id, params] = resolveIdAndParams(param)
    return axios.post(`/api/customers/admin/users/${id}/orders/store`, params)
  },
  resumeOrder(store, param) {
    const [id, params] = resolveIdAndParams(param)
    return axios.post(`/api/customers/admin/users/${id}/orders/${params.order_id}/resume`, params)
  },
  cancelOrder(store, param) {
    const [id, params] = resolveIdAndParams(param)
    return axios.post(`/api/customers/admin/users/${id}/orders/${params.order_id}/cancel`, params)
  },
  fetchEligibleRogueOrder(store, {userId}) {
    return axios.get(`/api/sales/admin/users/${userId}/rogue-order-delivery-quote`, { params: { include: ['orderWeek', 'productionCycle'] } })
  },
  createRogueOrder(store, {userId, payload}) {
    return axios.post(`/api/sales/admin/users/${userId}/rogue-orders`, payload)
  },
}
