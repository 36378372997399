<template>
  <modal
    :scrollable="true"
    :adaptive="true"
    :draggable="true"
    name="unskip-post-cutoff"
    @before-open="beforeOpen"
    height="auto"
    classes="w-full bg-white rounded-lg overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full"
  >
    <div>
      <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
        <div class="sm:flex sm:items-center">
          <div
            class="mx-auto flex h-12 w-12 shrink-0 items-center justify-center rounded-full bg-teal-100 sm:mx-0 sm:h-10 sm:w-10"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6 text-teal-600"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
              />
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
              />
            </svg>
          </div>
          <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
            <h3 class="text-lg font-medium leading-6 text-gray-900">Unskip Order</h3>
          </div>
        </div>
      </div>
      <hr />
      <div class="bg-gray-100 px-4 py-3 sm:flex sm:flex-row-reverse sm:items-center sm:px-6">
        <span class="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
          <button
            @click.prevent="confirm"
            type="button"
            class="focus:shadow-outline-teal inline-flex w-full justify-center rounded-md border border-transparent bg-teal-600 px-4 py-2 text-base font-medium leading-6 text-white shadow-sm transition duration-150 ease-in-out hover:bg-teal-500 focus:border-teal-700 focus:outline-none sm:text-sm sm:leading-5"
          >
            Unskip Order
          </button>
        </span>
        <span class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto">
          <button
            @click.prevent="close"
            type="button"
            class="focus:shadow-outline inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium leading-6 text-gray-700 shadow-sm transition duration-150 ease-in-out hover:text-gray-500 focus:border-blue-300 focus:outline-none sm:text-sm sm:leading-5"
          >
            Cancel
          </button>
        </span>
        <div class="text-sm text-red-500 sm:w-1/2" v-if="firstError">{{ firstError }}</div>
      </div>
    </div>
  </modal>
</template>

<script>
import _ from 'lodash'
import { mapGetters, mapActions } from 'vuex'
import HasErrors from '~/mixins/HasErrors'
export default {
  mixins: [HasErrors],
  props: ['orderId', 'user'],
  data: () => ({
    loading: false,
  }),
  computed: {
    ...mapGetters(['orders/byId']),
    order() {
      return this['orders/byId'](this.orderId)
    },
  },
  methods: {
    ...mapActions([
      'orders/postCutoffUnskip',
      'reasons/fetchSkipReasons',
      'tags/fetch',
      'indicators/fetch',
      'age-groups/fetch',
    ]),
    beforeOpen() {
      this['tags/fetch']()
      this['indicators/fetch']()
      this['age-groups/fetch']()
    },
    async confirm() {
      this.loading = true

      try {
        await this.wrapSubmit(
          this['orders/postCutoffUnskip']({ orderId: this.orderId, userId: this.user.id })
        )
        this.successMessage('Success')
        this.close()
      } catch (e) {
        this.errorMessage('Your request failed')
      } finally {
        this.loading = false
      }
    },
    close() {
      this.$modal.hide('unskip-post-cutoff')
    },
  },
}
</script>
