<template>
  <div class="h-full grow overflow-auto bg-gray-200 p-4 sm:mt-6">
    <div v-if="user" class="justify-between space-x-4 sm:flex sm:items-center">
      <button class="text-gray-700 underline hover:text-gray-800">
        <router-link :to="{ name: 'edit-user', params: { id: user.id } }">
          <i class="fas fa-long-arrow-alt-left mr-2" />
          Back to {{ user.email }} details
        </router-link>
      </button>
    </div>

    <div v-if="rogueOrder && !isLoading" class="bg-white">
      <PanelSection>
        <div class="border-b border-gray-300 bg-white px-4 py-3">
          <h4 class="text-base font-semibold">Create rogue order</h4>
        </div>

        <div class="bg-gray-100 px-4 pb-6 pt-2 sm:pt-6">
          <div class="mb-1 font-semibold uppercase tracking-wider text-gray-600">
            Order details:
          </div>
          <div>
            <span class="font-medium">{{ rogueOrder.productionCycle.name }}</span>
            <span class="mx-2">&bull;</span>
            <span class="font-medium">
              {{ rogueOrder.orderWeek.number }} (start: {{ rogueOrder.orderWeek.date }})
            </span>
            <span class="mx-2">&bull;</span>
            <span class="font-medium">Cutoff: {{ rogueOrder.cutoff_date }}</span>
          </div>
        </div>

        <div class="mt-6">
          <h4 class="text-base font-semibold">Details</h4>
          <div class="mt-2">
            <div class="border-b-2 border-gray-400 border-opacity-50 pb-2">
              <p class="text-sm leading-5 text-gray-500">
                Please select the meals to generate the order with. Once created, order will be
                unlocked for post cutoff changes.
              </p>
            </div>
            <display-preferences :profileId="user.profile" />
            <div class="mt-4">
              <input
                type="text"
                v-model="filter"
                placeholder="Filter by name or SKU"
                class="form-input w-full"
              />
            </div>
            <div v-if="products.length > 0" class="mt-2">
              <fieldset class="mt-4">
                <div class="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3">
                  <div
                    v-for="product in products"
                    :key="product.sku"
                    class="rounded-md border-2 border-gray-200 border-opacity-50 bg-white p-4"
                  >
                    <div class="flex items-center">
                      <input
                        :id="`product-sku-${product.sku}`"
                        v-on:change="toggleSelected(product.sku)"
                        :checked="isSelected(product.sku)"
                        type="checkbox"
                        class="form-checkbox h-4 w-4 cursor-pointer text-teal-600 transition duration-150 ease-in-out"
                      />
                      <label
                        :for="`product-sku-${product.sku}`"
                        class="ml-3 flex cursor-pointer items-center"
                      >
                        <span
                          class="block text-sm leading-5"
                          :class="{ 'text-teal-900': isSelected(product.sku) }"
                        >
                          {{ product.sku }}
                        </span>

                        <span
                          class="ml-1 block text-sm leading-2 text-gray-500"
                          :class="{ 'text-teal-700': isSelected(product.sku) }"
                        >
                          {{ product.name }}
                        </span>
                      </label>
                    </div>
                    <img
                      :src="product.image['landing-desktop']"
                      alt="Product Image"
                      class="mt-2 h-32 w-full rounded-md object-cover"
                    />
                    <div class="flex justify-center">
                      <selected-product-quantity-for-unskipping-post-cutoff
                        class="mt-2"
                        v-if="isSelected(product.sku)"
                        v-model="findSelected(product.sku).quantity"
                        :available="product.buffer_quantity"
                      />
                    </div>
                  </div>
                </div>
              </fieldset>

              <InputGroup
                label="Promo code"
                help-text="If you'd want a promo code applied on the account"
                class="mt-4"
              >
                <input type="text" class="form-input w-full" v-model="form.promoCode" />
              </InputGroup>

              <button
                @click.prevent="confirm"
                :disabled="isSubmitting"
                type="button"
                class="focus:shadow-outline-teal mt-4 inline-flex w-36 justify-center rounded-md border border-transparent bg-teal-600 px-4 py-2 text-base font-medium leading-6 text-white shadow-sm transition duration-150 ease-in-out hover:bg-teal-500 focus:border-teal-700 focus:outline-none sm:text-sm sm:leading-5"
              >
                {{ isSubmitting ? 'Just a moment' : 'Create Order' }}
              </button>
            </div>
            <p v-if="products.length == 0" class="text-sm leading-5 text-gray-500 mt-3">
              <span v-if="isProductsLoading">
                Loading...
              </span>
              <span v-else>
                There are no meals with this SKU or Name.
              </span>
            </p>
          </div>
        </div>
      </PanelSection>
    </div>
    <div v-if="!rogueOrder && !isLoading" class="px-3 py-2 text-sm">
      {{
        createRogueOrderMessage ??
        'No active post cutoff cycle found. We cant create a rogue order.'
      }}
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import { mapActions, mapGetters } from 'vuex'
import { mapDataKeysToParent } from '@/utils/mapDataKeysToParent'
import DisplayPreferences from '@/components/post-cutoff/DisplayPreferences'
import SelectedProductQuantityForUnskippingPostCutoff from '@/components/post-cutoff/SelectedProductQuantityForUnskippingPostCutoff'
import HasErrors from '~/mixins/HasErrors'

export default {
  mixins: [HasErrors],
  components: {
    DisplayPreferences,
    SelectedProductQuantityForUnskippingPostCutoff,
  },
  data() {
    return {
      rogueOrder: null,
      isLoading: false,
      isSubmitting: false,
      productSkus: [],
      isProductsLoading: false, 
      filter: '',
      form: {
        selectedProducts: [],
        promoCode: null,
      },
      createRogueOrderMessage: null,
    }
  },
  async mounted() {
    await this['users/fetchById']({ id: this.userId })
    await this.fetchPostCutoffRogueOrder()
    this['tags/fetch']()
    this['indicators/fetch']()
    this.fetchProducts()
  },
  computed: {
    user() {
      return this['users/byId'](this.userId)
    },
    userId() {
      return +this.$route.params.id
    },
    products() {
      return _.map(this.productSkus, sku => this['products/bySKU'](sku))
    },
    ...mapGetters(['users/byId', 'orders/byId', 'products/bySKU']),
  },
  watch: {
    filter: _.debounce(function (newFilter) {
      this.fetchProducts()
    }, 300),
  },

  methods: {
    async fetchProducts() {
      this.isProductsLoading = true;
      this['products/fetchForRogueOrder']({
        orderWeekId: this.rogueOrder.orderWeek.id,
        filter: this.filter,
      }).then(data => {
        this.productSkus = _.map(data.data, el => el.sku);
        this.isProductsLoading = false;
      }).finally(() => {
        this.isProductsLoading = false;
      })
    },
    async fetchPostCutoffRogueOrder() {
      this.isLoading = true
      try {
        const res = await this['orders/fetchEligibleRogueOrder']({ userId: this.userId })
        this.rogueOrder = mapDataKeysToParent(res.data)
      } catch (e) {
        if (e.response.data.message) {
          this.createRogueOrderMessage = e.response.data.message
        }
      }
      this.isLoading = false
    },
    async confirm() {
      this.isSubmitting = true
      try {
        const res = await this.wrapSubmit(
          this['orders/createRogueOrder']({
            userId: this.user.id,
            payload: {
              production_cycle_id: this.rogueOrder.productionCycle.id,
              order_week_id: this.rogueOrder.orderWeek.id,
              items: this.form.selectedProducts,
              promo_code: this.form.promoCode,
            },
          })
        )

        this.successMessage(
          'Success. Redirecting to Post cutoff order..',
          res.data.message,
          3000,
          () => this.$router.push({ name: 'post-cutoff', params: { userId: this.user.id } })
        )
      } catch (err) {
        this.errors = err.response.data.errors ?? []
        this.errorMessage(
          'The request failed',
          this.firstError ?? err.response.data.message ?? null,
          10000
        )
      } finally {
        this.isSubmitting = false
      }
    },
    toggleSelected(sku) {
      if (this.isSelected(sku)) {
        this.form.selectedProducts.splice(
          this.form.selectedProducts.indexOf(this.findSelected(sku)),
          1
        )
      } else {
        this.form.selectedProducts.push({ sku, quantity: 1 })
      }
    },
    findSelected(sku) {
      return this.form.selectedProducts.find(item => item.sku == sku)
    },
    isSelected(sku) {
      return this.findSelected(sku)
    },
    ...mapActions([
      'orders/fetchEligibleRogueOrder',
      'reasons/fetchSkipReasons',
      'products/fetchForRogueOrder',
      'tags/fetch',
      'indicators/fetch',
      'users/fetchById',
      'orders/createRogueOrder',
    ]),
  },
}
</script>
