<template>
  <modal
    :adaptive="true"
    name="skip-post-cutoff"
    width="800px"
    @before-open="beforeOpen"
    height="auto"
    classes="w-full bg-white rounded-lg overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full"
  >
    <div class="">
      <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
        <div class="sm:flex sm:items-center">
          <div
            class="mx-auto flex h-12 w-12 shrink-0 items-center justify-center rounded-full bg-orange-100 sm:mx-0 sm:h-10 sm:w-10"
          >
            <svg
              class="h-6 w-6 text-orange-600"
              stroke="currentColor"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
              />
            </svg>
          </div>
          <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
            <h3 class="text-lg font-medium leading-6 text-gray-900">Skip Order</h3>
          </div>
        </div>
      </div>
      <div v-if="reasons.length" class="px-4 pt-5 sm:p-6 sm:pb-4">
        <div :class="{ 'has-error': hasError('reasons') }">
          <error :error="getError('reasons')" />
          <div class="radio mb-1" v-for="reason in reasons" :key="reason.id">
            <label class="inline-flex items-center">
              <input
                type="radio"
                :value="reason.id"
                v-model="form.reason.reason_id"
                class="form-radio h-5 w-5 text-teal-600"
              />
              <span class="ml-2 text-gray-700">{{ reason.title }}</span>
            </label>
            <div v-if="form.reason.reason_id === reason.id" class="ml-6 mt-1">
              <div v-if="reason.description" v-html="reason.description"></div>
              <div v-if="reason.question">
                <p class="text-sm text-gray-700">{{ reason.question }}</p>
                <textarea v-model="form.reason.details" class="form-input mt-1 block w-full" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div class="bg-gray-100 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
        <span class="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
          <button
            @click.prevent="confirm"
            type="button"
            class="focus:shadow-outline-orange inline-flex w-full justify-center rounded-md border border-transparent bg-orange-600 px-4 py-2 text-base font-medium leading-6 text-white shadow-sm transition duration-150 ease-in-out hover:bg-orange-500 focus:border-orange-700 focus:outline-none sm:text-sm sm:leading-5"
          >
            Skip Order
          </button>
        </span>
        <span class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto">
          <button
            @click.prevent="close"
            type="button"
            class="focus:shadow-outline inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium leading-6 text-gray-700 shadow-sm transition duration-150 ease-in-out hover:text-gray-500 focus:border-blue-300 focus:outline-none sm:text-sm sm:leading-5"
          >
            Cancel
          </button>
        </span>
      </div>
    </div>
  </modal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import HasErrors from '~/mixins/HasErrors'
export default {
  mixins: [HasErrors],
  props: ['orderId', 'user'],
  data: () => ({
    loading: false,
    form: {
      reason: {
        reason_id: null,
        details: null,
      },
      funds_return: {
        is_active: true,
        amount: null,
      },
    },
  }),
  computed: {
    ...mapGetters(['reasons/skip', 'reasons/byId']),
    selectedReason() {
      return this['reasons/byId'](this.form.reason.reason_id)
    },
    reasons() {
      return this['reasons/skip']
    },
  },
  methods: {
    ...mapActions(['reasons/fetchSkipReasons', 'orders/postCutoffSkip']),
    beforeOpen() {
      this['reasons/fetchSkipReasons']()
    },
    async confirm() {
      if (!this.validate() || this.loading) {
        return false
      }

      this.loading = true

      const data = {
        reason_id: this.form.reason.reason_id,
        ...(this.form.reason.details ? { details: this.form.reason.details } : {} ),
        ...(this.form.funds_return.is_active
          ? {
              funds_return: {
                type: 'credit',
                amount: this.form.funds_return.amount ? this.form.funds_return.amount : null,
              },
            }
          : {}),
      }

      try {
        await this.wrapSubmit(
          this['orders/postCutoffSkip']({ orderId: this.orderId, userId: this.user.id, data })
        )
        this.successMessage('Success')
        this.close()
      } catch (e) {
        this.errorMessage('Error occurred')
      } finally {
        this.loading = false
      }
    },
    close() {
      this.$modal.hide('skip-post-cutoff')
    },
    validate() {
      this.resetErrors()

      if (!this.form.reason.reason_id) {
        this.errors.reasons = ['Please select a skip reason.']
        return false
      }

      if (this.selectedReason.answer_required && !this.form.reason.details) {
        this.errors.reasons = ['Please provide a response to: ' + this.selectedReason.question]
        return false
      }

      return true
    },
  },
}
</script>
