<template>
  <div class="h-full grow overflow-auto bg-gray-100 p-5">
    <div class="flex justify-between py-4">
      <h3 class="text-3xl">Price variance groups</h3>

    </div>

    <table class="mt-4 w-full table-auto bg-white">
      <thead>
      <tr class="border-b-2 font-bold">
        <th class="w-8 border px-4 py-2 text-sm font-semibold uppercase text-gray-600">ID</th>
        <th class="border px-4 py-2 text-left text-sm font-semibold uppercase text-gray-600">
          Kids meals
        </th>
        <th class="border px-4 py-2 text-left text-sm font-semibold uppercase text-gray-600">
          Finger foods
        </th>
        <th class="border px-4 py-2 text-left text-sm font-semibold uppercase text-gray-600">
          Created At
        </th>
        <th class="border px-4 py-2 text-left text-sm font-semibold uppercase text-gray-600">
          Deactivated At
        </th>
      </tr>
      </thead>

      <tbody>
      <tr v-for="group in variances" :key="group.id" class="text-center">
        <td class="border px-4 py-2">
          {{ group.id }}
        </td>
        <td class="border px-4 py-2">
            <span class="rounded-sm px-2 text-xs font-semibold uppercase text-teal-600">${{
                group.prices['kids-meals']
              }}</span>
        </td>
        <td class="border px-4 py-2">
            <span class="rounded-sm px-2 text-xs font-semibold uppercase text-teal-600">${{
                group.prices['finger-foods']
              }}</span>
        </td>
        <td class="border px-4 py-2">
            <span class="rounded-sm px-2 text-xs font-semibold uppercase text-teal-600">{{
                group.created_at
              }}</span>
        </td>
        <td class="border px-4 py-2">
            <span class="rounded-sm px-2 text-xs font-semibold uppercase text-teal-600">{{
                group.deactivated_at
              }}</span>
        </td>
        <td class="border px-4 py-2">
          <button :class="group.deactivated_at ? 'btn-green' : 'btn-red'" class="btn"
                  @click.prevent="toggleStatus(group)">
            {{ group.deactivated_at ? 'Activate' : 'Deactivate' }}
          </button>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      variances: 'price-variance-groups/all'
    })
  },

  mounted() {
    this.fetchItems({})
  },

  methods: {
    ...mapActions({
      fetchItems: 'price-variance-groups/fetch',
      toggleGroupStatus: 'price-variance-groups/update'
    }),
    toggleStatus(group) {
      console.log(group)
      this.toggleGroupStatus({
        id: group.id,
        params: { deactivated_at: group.deactivated_at ? null : new Date().toISOString() }
      })
        .then(result => window.location.reload())
    }
  }
}
</script>
