import * as types from '../mutation-types'
import { addOrUpdateItems, processEntities } from '../helpers'
import axios from 'axios'

export const state = {
  items: [],
  groups: [],
}

export const getters = {
  all: state => state.items,
  groups: state => state.groups,
}

export const mutations = {
  [types.BATCH_ADD_OR_UPDATE]: (state, items) => state.items = items,
  setGroups: (state, groups) => state.groups = groups,
  reset(state) {
    state.items = []
    state.groups = []
  }
}

export const actions = {
  async fetch(store) {
    return await axios.get('/api/admin/timeline').then(res => {
      store.commit('reset')
      store.commit(types.BATCH_ADD_OR_UPDATE, res.data.data)
      store.commit('setGroups', res.data.meta.data.map(productionCycle => ({ id: productionCycle.id, content: productionCycle.name })))
      return res.data.data
    })
  },
}
