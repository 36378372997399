<template>
  <div class="relative bg-gray-100">

      <navbar />

      <main class="mx-auto flex max-w-7xl">
        <sidebar class="hidden w-1/4 md:block" />
        <div class="mx-auto min-h-screen w-full">
          <slot />
        </div>
      </main>

      <div>
        <timeline />
      </div>

    <div>
      <Nlfooter />
    </div>

    </div>
</template>

<script>
import Navbar from '~/components/Navbar'
import Sidebar from '~/components/Sidebar'
import Timeline from '~/components/Timeline.vue'
import Nlfooter from '~/components/Footer.vue'

export default {
  components: {
    Navbar,
    Sidebar,
    Timeline,
    Nlfooter
  },
}
</script>
