<template>
  <div class="mt-10 overflow-hidden rounded-lg bg-white p-4 shadow">
    <h4 class="mb-5 font-bold">Price variance group = {{this.user.price_variance_group_id}}</h4>
    <a href="#" @click="openModal" class="link">Update group...</a>
    <modal :adaptive="true" height="auto" name="list-price-variance-group">
      <div class="m-4">
        <h3 class="text-xl font-bold">Choose price variance group</h3>
        <hr />
        <form @submit.prevent="save">
          <table class="table table-striped table-borderless mb-5">
            <thead>
            <tr>
            <th>
              Variant
            </th>
            <th>Kids Meals</th>
            <th>Finger Foods</th>
            <th></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="variance in variances" :key="variance.id" >
              <td><input v-model="price_variance_group"
                         :disabled="!variance.is_active"
                         :value="variance.id"
                         type="radio" class="disabled">
                &nbsp;&nbsp;Price variance group {{ variance.id }} &nbsp;</td>
              <td>${{variance.prices['kids-meals']}}</td>
              <td>${{variance.prices['finger-foods']}}</td>
              <td><span class="text-red-400">{{!variance.is_active ? 'Deactivated' : ''}}</span></td>
            </tr>
            </tbody>
          </table>
          <br>
          <div>
            <button class="btn btn-green" @click.prevent="save">Confirm</button>
            <button class="btn btn-gray" @click.prevent="close">Cancel</button>
          </div>
        </form>
      </div>
    </modal>
  </div>

</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import FormMixin from '~/mixins/FormMixin'

export default {
  props: ['user'],
  mixins: [FormMixin],
  data() {
    return {
      price_variance_group: null
    }
  },
  mounted() {
    this['price-variance-groups/fetch']()
    this.price_variance_group = this.user.price_variance_group_id
  },
  methods: {
    ...mapActions([
      'users/fetchById',
      'price-variance-groups/fetch',
      'price-variance-groups/savePriceVarianceForUser'
    ]),
    save() {
      const params = {
        id: this.user.id,
        price_variance_group: this.price_variance_group
      }

      this.wrapSubmit(this['price-variance-groups/savePriceVarianceForUser'](params))
        .then(() => {
          this.successMessage('Price variance updated!')
          this['users/fetchById'](this.user.id)
          this.close()
        })
        .catch(() => {
          this.errorMessage('Error Occurred!')
        })
    },

    openModal() {
      this.$modal.show('list-price-variance-group')
    },
    close() {
      this.$modal.hide('list-price-variance-group')
    }
  },

  computed: {
    ...mapGetters({
      variances: 'price-variance-groups/all'
    }),
    isDirty() {
      return this.price_variance_group !== this.user.price_variance_group_id
    }
  },
}
</script>
