<template>
  <span class="relative z-0 inline-flex rounded-md shadow-sm">
    <button
      v-on:click.prevent.stop="decrement"
      type="button"
      class="relative inline-flex items-center rounded-l border border-teal-800 border-opacity-25 bg-gray-300 bg-opacity-25 p-1 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:border-teal-500 focus:outline-none focus:ring-1 focus:ring-teal-500"
    >
      <svg
        class="h-4 w-4"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M20 12H4" />
      </svg>
    </button>
    <span
      class="relative inline-flex items-center border border-r-0 border-l-0 border-teal-800 border-opacity-25 bg-gray-300 bg-opacity-25 px-3 py-1 text-sm font-semibold text-gray-700"
    >
      {{ value }}
    </span>
    <button
      v-on:click.prevent.stop="increment"
      type="button"
      class="relative inline-flex items-center rounded-r border border-teal-800 border-opacity-25 bg-gray-300 bg-opacity-25 p-1 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:border-teal-500 focus:outline-none focus:ring-1 focus:ring-teal-500"
    >
      <svg
        class="h-4 w-4"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M12 6v6m0 0v6m0-6h6m-6 0H6"
        />
      </svg>
    </button>
  </span>
</template>

<script>
export default {
  props: ['value',],
  data() {
    return {
      count: 0,
    }
  },
  methods: {
    increment() {
      this.count++
      this.sync()
    },
    decrement() {
      if (this.count === 1) {
        return
      }
      this.count--
      this.sync()
    },
    sync() {
      this.$emit('input', this.count)
    },
  },
  mounted() {
    this.count = this.value
  },
}
</script>
