<template>
  <div>
    <aside class="flex h-full max-h-screen min-h-screen">
      <div class="mt-8 grow overflow-auto">
        <nav class="block bg-transparent">
          <ul>
            <sidebar-link route-name="users" name="Users">
              <template v-slot:svg>
                <svg
                  class="h-4 w-4 stroke-current text-gray-700 hover:text-teal-600"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                  <circle cx="9" cy="7" r="4"></circle>
                  <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
                  <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
                </svg>
              </template>
            </sidebar-link>
            <sidebar-link route-name="categories" name="Categories &amp; Pricing">
              <template v-slot:svg>
                <svg
                  class="h-4 w-4 stroke-current text-gray-700 hover:text-teal-600"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10"
                  />
                </svg>
              </template>
            </sidebar-link>
            <sidebar-link route-name="price-variance-groups" name="Price Variance Groups">
              <template v-slot:svg>
                <svg
                  class="h-4 w-4 stroke-current text-gray-700 hover:text-teal-600"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10"
                  />
                </svg>
              </template>
            </sidebar-link>
            <sidebar-link route-name="products" name="Products">
              <template v-slot:svg>
                <svg
                  class="h-4 w-4 stroke-current text-gray-700 hover:text-teal-600"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <line x1="16.5" y1="9.4" x2="7.5" y2="4.21"></line>
                  <path
                    d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"
                  ></path>
                  <polyline points="3.27 6.96 12 12.01 20.73 6.96"></polyline>
                  <line x1="12" y1="22.08" x2="12" y2="12"></line>
                </svg>
              </template>
            </sidebar-link>
            <sidebar-link route-name="menu-items" name="Marketing Menu">
              <template v-slot:svg>
                <svg
                  class="h-4 w-4 stroke-current text-gray-700 hover:text-teal-600"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M4 6h16M4 12h16m-7 6h7"
                  />
                </svg>
              </template>
            </sidebar-link>
            <sidebar-link route-name="marketing-pages" name="Marketing Pages">
              <template v-slot:svg>
                <svg
                  class="h-4 w-4 stroke-current text-gray-700 hover:text-teal-600"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <circle cx="12" cy="12" r="2"></circle>
                  <path d="M16.24 7.76a6 6 0 0 1 0 8.49m-8.48-.01a6 6 0 0 1 0-8.49m11.31-2.82a10 10 0 0 1 0 14.14m-14.14 0a10 10 0 0 1 0-14.14"></path>
                </svg>
              </template>
            </sidebar-link>
            <sidebar-link route-name="order-weeks" name="Order Weeks">
              <template v-slot:svg>
                <svg
                  class="h-4 w-4 stroke-current text-gray-700 hover:text-teal-600"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect>
                  <line x1="16" y1="2" x2="16" y2="6"></line>
                  <line x1="8" y1="2" x2="8" y2="6"></line>
                  <line x1="3" y1="10" x2="21" y2="10"></line>
                </svg>
              </template>
            </sidebar-link>
            <sidebar-link route-name="product-rotations" name="Product Rotations">
              <template v-slot:svg>
                <svg
                  class="h-4 w-4 stroke-current text-gray-700 hover:text-teal-600"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <polyline points="23 4 23 10 17 10"></polyline>
                  <path d="M20.49 15a9 9 0 1 1-2.12-9.36L23 10"></path>
                </svg>
              </template>
            </sidebar-link>
            <sidebar-link route-name="bestsellers" name="Bestsellers">
              <template v-slot:svg>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-4 w-4 stroke-current text-gray-700 hover:text-teal-600"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z"
                  />
                </svg>
              </template>
            </sidebar-link>
            <sidebar-link route-name="upsells" name="Upsell">
              <template v-slot:svg>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-4 w-4 stroke-current text-gray-600 hover:text-teal-600"
                  fill="currentColor"
                  viewBox="0 0 80.63 122.88"
                  stroke="currentColor"
                >
                  <path d="M68.82,111.07A40.31,40.31,0,1,1,25.69,45V39H12.3c-2.24-.1-3.82-.84-4.76-2.24-2.3-3.45.43-6.85,2.65-9.34C16.56,20.22,32.58,6.08,36,2.08a5.48,5.48,0,0,1,8.59,0C47.91,6,63.1,19.31,69.81,26.67c2.36,2.61,5.77,6.31,3.28,10-.92,1.4-2.52,2.14-4.76,2.24H54.9v6a40.31,40.31,0,0,1,13.92,66.09ZM53.87,88.32a11.38,11.38,0,0,1-2.43,7.11A12.64,12.64,0,0,1,44,99.77l-.45,3.85H36l-.92-1,.38-2.46a49.66,49.66,0,0,1-8.69-1.71l1.78-10.6L29.65,87a24.93,24.93,0,0,0,5.59,3.36,13.21,13.21,0,0,0,5,1.32c1,0,1.58-.35,1.58-1.06a1.47,1.47,0,0,0-.89-1.28A27.32,27.32,0,0,0,37.81,88a35.28,35.28,0,0,1-5-2.17,10.65,10.65,0,0,1-3.56-3.26,9.19,9.19,0,0,1-1.51-5.43A11,11,0,0,1,30.24,70a13,13,0,0,1,7-4.24l.36-4.23H45l1.12,1-.3,2.71a30.56,30.56,0,0,1,7.44,1.65L51.51,77l-1.72.46A23.37,23.37,0,0,0,45,75a12.09,12.09,0,0,0-3.85-.92c-.92,0-1.38.28-1.38.85a1.56,1.56,0,0,0,1,1.39,28.69,28.69,0,0,0,3.19,1.38,35.25,35.25,0,0,1,5,2.14A10.24,10.24,0,0,1,52.39,83a8.88,8.88,0,0,1,1.48,5.3Zm-13.56,26A31.77,31.77,0,1,0,8.55,82.57a31.76,31.76,0,0,0,31.76,31.76Z" />
                </svg>
              </template>
            </sidebar-link>
            <sidebar-link route-name="promo-codes" name="Promo Codes">
              <template v-slot:svg>
                <svg
                  class="h-4 w-4 stroke-current text-gray-700 hover:text-teal-600"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <polyline points="20 12 20 22 4 22 4 12"></polyline>
                  <rect x="2" y="7" width="20" height="5"></rect>
                  <line x1="12" y1="22" x2="12" y2="7"></line>
                  <path d="M12 7H7.5a2.5 2.5 0 0 1 0-5C11 2 12 7 12 7z"></path>
                  <path d="M12 7h4.5a2.5 2.5 0 0 0 0-5C13 2 12 7 12 7z"></path>
                </svg>
              </template>
            </sidebar-link>
            <sidebar-link route-name="gift-cards" name="Gift Cards">
              <template v-slot:svg>
                <svg
                  class="h-4 w-4 stroke-current text-gray-700 hover:text-teal-600"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <polyline points="20 12 20 22 4 22 4 12"></polyline>
                  <rect x="2" y="7" width="20" height="5"></rect>
                  <line x1="12" y1="22" x2="12" y2="7"></line>
                  <path d="M12 7H7.5a2.5 2.5 0 0 1 0-5C11 2 12 7 12 7z"></path>
                  <path d="M12 7h4.5a2.5 2.5 0 0 0 0-5C13 2 12 7 12 7z"></path>
                </svg>
              </template>
            </sidebar-link>
            <sidebar-link route-name="holidays" name="Holidays">
              <template v-slot:svg>
                <svg
                  class="h-4 w-4 stroke-current text-gray-700 hover:text-teal-600"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <circle cx="12" cy="12" r="5"></circle>
                  <line x1="12" y1="1" x2="12" y2="3"></line>
                  <line x1="12" y1="21" x2="12" y2="23"></line>
                  <line x1="4.22" y1="4.22" x2="5.64" y2="5.64"></line>
                  <line x1="18.36" y1="18.36" x2="19.78" y2="19.78"></line>
                  <line x1="1" y1="12" x2="3" y2="12"></line>
                  <line x1="21" y1="12" x2="23" y2="12"></line>
                  <line x1="4.22" y1="19.78" x2="5.64" y2="18.36"></line>
                  <line x1="18.36" y1="5.64" x2="19.78" y2="4.22"></line>
                </svg>
              </template>
            </sidebar-link>
            <sidebar-link route-name="zip-codes" name="Zip Codes">
              <template v-slot:svg>
                <svg
                  class="h-4 w-4 stroke-current text-gray-700 hover:text-teal-600"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path>
                  <circle cx="12" cy="10" r="3"></circle>
                </svg>
              </template>
            </sidebar-link>
            <sidebar-link route-name="carriers" name="Carriers">
              <template v-slot:svg>
                <svg
                  class="h-4 w-4 stroke-current text-gray-700 hover:text-teal-600"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 256 256"
                  fill="#475569"
                  stroke="currentColor"
                >
                <defs>
                </defs>
                <g style="stroke: none; stroke-width: 0; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: none; fill-rule: nonzero; opacity: 1;" transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)" >
                  <path d="M 65.28 64.301 H 39.272 c -1.104 0 -2 -0.896 -2 -2 s 0.896 -2 2 -2 H 63.28 v -35.42 c 0 -0.968 -0.788 -1.756 -1.756 -1.756 H 14.522 c -1.104 0 -2 -0.896 -2 -2 s 0.896 -2 2 -2 h 47.002 c 3.174 0 5.756 2.582 5.756 5.756 v 37.42 C 67.28 63.405 66.385 64.301 65.28 64.301 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(0,0,0); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
                  <path d="M 26.123 64.301 H 14.522 c -1.104 0 -2 -0.896 -2 -2 s 0.896 -2 2 -2 h 11.601 c 1.104 0 2 0.896 2 2 S 27.228 64.301 26.123 64.301 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(0,0,0); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
                  <path d="M 83.813 64.301 h -1.182 c -1.104 0 -2 -0.896 -2 -2 s 0.896 -2 2 -2 h 1.182 c 1.206 0 2.188 -0.981 2.188 -2.188 V 39.388 c 0 -2.212 -0.993 -4.271 -2.725 -5.648 l -5.361 -4.264 c -1.271 -1.012 -2.867 -1.569 -4.492 -1.569 H 67.28 v 32.395 h 2.201 c 1.104 0 2 0.896 2 2 s -0.896 2 -2 2 H 65.28 c -1.104 0 -2 -0.896 -2 -2 V 25.906 c 0 -1.104 0.896 -2 2 -2 h 8.142 c 2.526 0 5.006 0.866 6.982 2.438 l 5.361 4.264 C 88.457 32.749 90 35.949 90 39.388 v 18.726 C 90 61.525 87.225 64.301 83.813 64.301 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(0,0,0); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
                  <path d="M 88 46.329 H 75.08 c -2.439 0 -4.425 -1.985 -4.425 -4.425 V 25.906 c 0 -1.104 0.896 -2 2 -2 s 2 0.896 2 2 v 15.998 c 0 0.234 0.19 0.425 0.425 0.425 H 88 c 1.104 0 2 0.896 2 2 S 89.104 46.329 88 46.329 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(0,0,0); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
                  <path d="M 32.698 70.875 c -4.728 0 -8.575 -3.847 -8.575 -8.574 s 3.847 -8.574 8.575 -8.574 s 8.574 3.847 8.574 8.574 S 37.426 70.875 32.698 70.875 z M 32.698 57.727 c -2.522 0 -4.575 2.052 -4.575 4.574 s 2.052 4.574 4.575 4.574 s 4.574 -2.052 4.574 -4.574 S 35.22 57.727 32.698 57.727 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(0,0,0); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
                  <path d="M 76.057 70.875 c -4.729 0 -8.575 -3.847 -8.575 -8.574 s 3.847 -8.574 8.575 -8.574 c 4.728 0 8.574 3.847 8.574 8.574 S 80.784 70.875 76.057 70.875 z M 76.057 57.727 c -2.522 0 -4.575 2.052 -4.575 4.574 s 2.053 4.574 4.575 4.574 s 4.574 -2.052 4.574 -4.574 S 78.579 57.727 76.057 57.727 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(0,0,0); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
                  <path d="M 88 51.888 h -8.821 c -1.104 0 -2 -0.896 -2 -2 s 0.896 -2 2 -2 H 88 c 1.104 0 2 0.896 2 2 S 89.104 51.888 88 51.888 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(0,0,0); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
                  <circle cx="32.698" cy="62.298" r="1.668" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(0,0,0); fill-rule: nonzero; opacity: 1;" transform="  matrix(1 0 0 1 0 0) "/>
                  <circle cx="76.058" cy="62.298" r="1.668" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(0,0,0); fill-rule: nonzero; opacity: 1;" transform="  matrix(1 0 0 1 0 0) "/>
                  <path d="M 83.813 64.301 h -1.182 c -1.104 0 -2 -0.896 -2 -2 s 0.896 -2 2 -2 h 1.182 c 1.206 0 2.188 -0.981 2.188 -2.188 V 39.388 c 0 -2.212 -0.993 -4.271 -2.725 -5.648 l -5.361 -4.264 c -1.271 -1.012 -2.867 -1.569 -4.492 -1.569 H 67.28 v 32.395 h 2.201 c 1.104 0 2 0.896 2 2 s -0.896 2 -2 2 H 65.28 c -1.104 0 -2 -0.896 -2 -2 V 25.906 c 0 -1.104 0.896 -2 2 -2 h 8.142 c 2.526 0 5.006 0.866 6.982 2.438 l 5.361 4.264 C 88.457 32.749 90 35.949 90 39.388 v 18.726 C 90 61.525 87.225 64.301 83.813 64.301 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(0,0,0); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
                  <path d="M 31.03 52.329 H 7.911 c -1.104 0 -2 -0.896 -2 -2 s 0.896 -2 2 -2 H 31.03 c 1.104 0 2 0.896 2 2 S 32.134 52.329 31.03 52.329 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(0,0,0); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
                  <path d="M 26.675 35.097 H 10.181 c -1.104 0 -2 -0.896 -2 -2 s 0.896 -2 2 -2 h 16.494 c 1.104 0 2 0.896 2 2 S 27.779 35.097 26.675 35.097 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(0,0,0); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
                  <path d="M 45.276 54.324 c -0.307 0 -0.613 -0.07 -0.895 -0.211 C 36.081 49.962 31 43.549 30.791 36.958 c -0.115 -3.635 1.427 -6.742 4.126 -8.313 c 1.693 -0.985 5.213 -2.139 10.359 0.602 c 5.147 -2.741 8.667 -1.587 10.359 -0.602 c 2.699 1.571 4.241 4.679 4.126 8.313 c -0.209 6.592 -5.29 13.005 -13.591 17.156 C 45.889 54.254 45.583 54.324 45.276 54.324 z M 39.146 31.551 c -0.833 0 -1.583 0.182 -2.216 0.55 c -1.43 0.832 -2.21 2.556 -2.141 4.729 c 0.121 3.79 2.958 9.184 10.488 13.241 c 7.53 -4.056 10.367 -9.451 10.487 -13.24 c 0.069 -2.174 -0.711 -3.898 -2.141 -4.73 c -1.762 -1.025 -4.425 -0.604 -7.304 1.155 c -0.641 0.392 -1.446 0.392 -2.085 0 C 42.389 32.129 40.633 31.551 39.146 31.551 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(0,0,0); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
                  <path d="M 25.119 43.713 H 2 c -1.104 0 -2 -0.896 -2 -2 s 0.896 -2 2 -2 h 23.119 c 1.104 0 2 0.896 2 2 S 26.224 43.713 25.119 43.713 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(0,0,0); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
                </g>
                </svg>
              </template>
            </sidebar-link>
            <sidebar-link route-name="tax-rates" name="Tax Rates">
              <template v-slot:svg>
                <svg
                  class="h-4 w-4 stroke-current text-gray-700 hover:text-teal-600"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path d="M2 3h6a4 4 0 0 1 4 4v14a3 3 0 0 0-3-3H2z"></path>
                  <path d="M22 3h-6a4 4 0 0 0-4 4v14a3 3 0 0 1 3-3h7z"></path>
                </svg>
              </template>
            </sidebar-link>
            <sidebar-link route-name="order-bumps" name="Order Bumps">
              <template v-slot:svg>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-4 w-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M8 16H6a2 2 0 01-2-2V6a2 2 0 012-2h8a2 2 0 012 2v2m-6 12h8a2 2 0 002-2v-8a2 2 0 00-2-2h-8a2 2 0 00-2 2v8a2 2 0 002 2z"
                  />
                </svg>
              </template>
            </sidebar-link>
            <sidebar-link route-name="production-cycles" name="Production Cycles">
              <template v-slot:svg>
                <svg
                  class="h-4 w-4 stroke-current text-gray-700 hover:text-teal-600"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    d="M3 6l3 1m0 0l-3 9a5.002 5.002 0 006.001 0M6 7l3 9M6 7l6-2m6 2l3-1m-3 1l-3 9a5.002 5.002 0 006.001 0M18 7l3 9m-3-9l-6-2m0-2v2m0 16V5m0 16H9m3 0h3"
                  ></path>
                </svg>
              </template>
            </sidebar-link>
            <sidebar-link route-name="popup-icons" name="Popup Icons">
              <template v-slot:svg>
                <svg
                  class="h-4 w-4 stroke-current text-gray-700 hover:text-teal-600"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"
                  />
                </svg>
              </template>
            </sidebar-link>
            <sidebar-link route-name="sale-banners" name="Sale Banners">
              <template v-slot:svg>
                <svg
                    class="h-4 w-4 stroke-current text-gray-700 hover:text-teal-600"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <circle cx="12" cy="12" r="2"></circle>
                    <path d="M16.24 7.76a6 6 0 0 1 0 8.49m-8.48-.01a6 6 0 0 1 0-8.49m11.31-2.82a10 10 0 0 1 0 14.14m-14.14 0a10 10 0 0 1 0-14.14"></path>
                </svg>
              </template>
            </sidebar-link>
            <sidebar-link route-name="settings" name="Settings">
              <template v-slot:svg>
                <svg
                  class="h-4 w-4 stroke-current text-gray-600 hover:text-teal-600"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                  fill="currentColor"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path d="M0 416c0-17.7 14.3-32 32-32l54.7 0c12.3-28.3 40.5-48 73.3-48s61 19.7 73.3 48L480 384c17.7 0 32 14.3 32 32s-14.3 32-32 32l-246.7 0c-12.3 28.3-40.5 48-73.3 48s-61-19.7-73.3-48L32 448c-17.7 0-32-14.3-32-32zm192 0c0-17.7-14.3-32-32-32s-32 14.3-32 32s14.3 32 32 32s32-14.3 32-32zM384 256c0-17.7-14.3-32-32-32s-32 14.3-32 32s14.3 32 32 32s32-14.3 32-32zm-32-80c32.8 0 61 19.7 73.3 48l54.7 0c17.7 0 32 14.3 32 32s-14.3 32-32 32l-54.7 0c-12.3 28.3-40.5 48-73.3 48s-61-19.7-73.3-48L32 288c-17.7 0-32-14.3-32-32s14.3-32 32-32l246.7 0c12.3-28.3 40.5-48 73.3-48zM192 64c-17.7 0-32 14.3-32 32s14.3 32 32 32s32-14.3 32-32s-14.3-32-32-32zm73.3 0L480 64c17.7 0 32 14.3 32 32s-14.3 32-32 32l-214.7 0c-12.3 28.3-40.5 48-73.3 48s-61-19.7-73.3-48L32 128C14.3 128 0 113.7 0 96S14.3 64 32 64l86.7 0C131 35.7 159.2 16 192 16s61 19.7 73.3 48z"/>
                </svg>
              </template>
            </sidebar-link>
            <sidebar-link route-name="skip-pc-change-offers" name="Skip PC Change Offer">
              <template v-slot:svg>
                <svg
                  class="h-4 w-4 stroke-current text-gray-700 hover:text-teal-600"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <polyline points="20 12 20 22 4 22 4 12"></polyline>
                  <rect x="2" y="7" width="20" height="5"></rect>
                  <line x1="12" y1="22" x2="12" y2="7"></line>
                  <path d="M12 7H7.5a2.5 2.5 0 0 1 0-5C11 2 12 7 12 7z"></path>
                  <path d="M12 7h4.5a2.5 2.5 0 0 0 0-5C13 2 12 7 12 7z"></path>
                </svg>
              </template>
            </sidebar-link>
            <sidebar-link route-name="regenerate-orders" name="Regenerate Orders">
              <template v-slot:svg>
                <svg class="w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                  <path clip-rule="evenodd" fill-rule="evenodd" d="M4 2a1 1 0 011 1v2.101a7.002 7.002 0 0111.601 2.566 1 1 0 11-1.885.666A5.002 5.002 0 005.999 7H9a1 1 0 010 2H4a1 1 0 01-1-1V3a1 1 0 011-1zm.008 9.057a1 1 0 011.276.61A5.002 5.002 0 0014.001 13H11a1 1 0 110-2h5a1 1 0 011 1v5a1 1 0 11-2 0v-2.101a7.002 7.002 0 01-11.601-2.566 1 1 0 01.61-1.276z"></path>
                </svg>
              </template>
            </sidebar-link>
            <sidebar-link route-name="mass-apply-credits" name="Mass Apply Credits">
              <template v-slot:svg>
                <svg class="w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                  <path d="M1 4.25a3.733 3.733 0 012.25-.75h13.5c.844 0 1.623.279 2.25.75A2.25 2.25 0 0016.75 2H3.25A2.25 2.25 0 001 4.25zM1 7.25a3.733 3.733 0 012.25-.75h13.5c.844 0 1.623.279 2.25.75A2.25 2.25 0 0016.75 5H3.25A2.25 2.25 0 001 7.25zM7 8a1 1 0 011 1 2 2 0 104 0 1 1 0 011-1h3.75A2.25 2.25 0 0119 10.25v5.5A2.25 2.25 0 0116.75 18H3.25A2.25 2.25 0 011 15.75v-5.5A2.25 2.25 0 013.25 8H7z"></path>
                </svg>
              </template>
            </sidebar-link>
            <sidebar-link route-name="experiments" name="Experiments">
              <template v-slot:svg>
                <BeakerIcon class="w-4 h-4" />
              </template>
            </sidebar-link>
          </ul>
        </nav>
      </div>
    </aside>
  </div>
</template>

<script>
import { BeakerIcon } from '@vue-hero-icons/outline';
import menuItems from '~/router/menu-items';
import SidebarLink from './SidebarLink.vue';
export default {
  components: { SidebarLink, BeakerIcon },
  data() {
    return { menuItems }
  },
}
</script>
