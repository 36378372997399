export default [
  { name: 'Users', route: 'users' },
  { name: 'Products', route: 'products' },
  { name: 'Categories & Pricing', route: 'products' },
  { name: 'Marketing Menu', route: 'menu-items' },
  { name: 'Product Rotations', route: 'product-rotations' },
  { name: 'Bestsellers', route: 'bestsellers' },
  { name: 'Promo Codes', route: 'promo-codes' },
  { name: 'Zip Codes', route: 'zip-codes' },
  { name: 'Tax Rates', route: 'tax-rates' },
  { name: 'Order Weeks', route: 'order-weeks' },
]
