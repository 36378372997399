<template>
  <div class="mt-20 pb-20 text-center text-sm text-gray-500">
    Designed by Mayven Studios
    <p>&copy; Nurturelife {{ currentYear }}</p>
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentYear: new Date().getFullYear()
    };
  }
};
</script>
