import axios from 'axios'
import _ from 'lodash'
import { headersFormData, objectToFormData } from '~/utils/form-data'
import { packageItems } from '../extras/products-presets'
import { addOrUpdateItems, processEntities } from '../helpers'
import * as types from '../mutation-types'
const INCLUDES_RELATIONS = [
  'allergens',
  'indicators',
  'mealComponents',
  'ratingAttributes',
  'category',
  'badge',
  'tags',
  'relatedProducts',
  'customersAlsoLovedProducts',
  'heatingInstructions',
  'icons',
  'composables',
]
export const state = {
  items: [],
  allergens: [],
  indicators: [],
  mealComponents: [],
  ratingAttributes: [],
  nutrition_highlights: [],
  packageItems,
}
export const getters = {
  all: state => state.items,
  packageItems: state => state.packageItems,
  allergens: state => state.allergens,
  indicators: state => state.indicators,
  mealComponents: state => state.mealComponents,
  ratingAttributes: state => state.ratingAttributes,
  bySKU: state => sku => _.find(state.items, item => item.sku === sku),
  bySKUs: state => SKUs => _.filter(state.items, item => SKUs.includes(item.sku)),
}
export const mutations = {
  [types.BATCH_ADD_OR_UPDATE]: (state, items) => addOrUpdateItems(state, items, 'sku'),
  [types.BATCH_ADD_OR_UPDATE_PRODUCTS_DICTIONARY]: (state, { items, node }) =>
    addOrUpdateItems(state, items, 'id', node),
}
export const actions = {
  fetch(store, params) {
    return axios.get('api/admin/products', { params }).then(res => {
      processEntities(res.data.data, 'products')
      return res.data
    })
  },
  async fetchBySku(store, sku) {
    const res = await axios.get(`api/admin/products/${sku}`, {
      params: { include: INCLUDES_RELATIONS },
    })
    processEntities(res.data.data, 'products')
    return res.data
  },
  fetchForRogueOrder(store, {orderWeekId, filter}) {
    return axios.get(`api/admin/products?order_week_id=${orderWeekId}&perPage=1000&nameOrSku=${filter}`).then(res => {
      processEntities(res.data.data, 'products')
      return res.data
    })
  },
  create(store, form) {
    return axios.post('api/admin/products', objectToFormData(form), headersFormData).then(res => {
      processEntities(res.data.data, 'products')
      return res.data
    })
  },
  update(store, { sku, form }) {
    const formData = new FormData()
    formData.append('_method', 'PUT')
    return axios
      .post(`/api/admin/products/${sku}`, objectToFormData(form, formData), {
        ...headersFormData,
        params: { include: INCLUDES_RELATIONS },
      })
      .then(res => {
        processEntities(res.data.data, 'products')
        return res.data
      })
  },
  clone(store, sku) {
    return axios.post(`api/admin/products/${sku}/clones`).then(res => {
      process(store, res.data.data)
      return res.data
    })
  },
  fetchAllergens({ commit }) {
    return axios.get('api/product-filters/allergens').then(res => {
      commit(types.BATCH_ADD_OR_UPDATE_PRODUCTS_DICTIONARY, {
        items: res.data.data,
        node: 'allergens',
      })
      return res.data
    })
  },
  fetchIndicators({ commit }) {
    return axios.get('api/product-filters/indicators').then(res => {
      commit(types.BATCH_ADD_OR_UPDATE_PRODUCTS_DICTIONARY, {
        items: res.data.data,
        node: 'indicators',
      })
      return res.data
    })
  },
  async deleteImage(store, { productSku, imageId }) {
    await axios.delete(`api/admin/products/${productSku}/media/${imageId}`)
  },
}
