<template>
  <section class="mt-10">
    <h1 class="mt-10 mb-5 ml-5 font-semibold text-gray-500">Production Timeline (CST)</h1>
    <div id="timeline"></div>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      items: 'timelines/all',
      groups: 'timelines/groups',
    }),
  },
  methods: {
    ...mapActions({
      fetch: 'timelines/fetch',
    }),
  },
  async mounted() {
    await this.fetch()

    const today = new Date();
    const oneWeekAgo = (new Date()).setDate(today.getDate() - 4);
    const oneWeekLater = (new Date()).setDate(today.getDate() + 5);

    const items = new vis.DataSet(this.items)

    const groups = new vis.DataSet(this.groups)

    const options = {
      showCurrentTime: true,
      zoomable: true,
      stack: false,
      start: oneWeekAgo,
      end: oneWeekLater
    }

    const container = document.getElementById('timeline')
    const timeline = new vis.Timeline(container, items, groups, options)

  }
}
</script>

<style scoped>

#timeline {
  width: 100%;
}

</style>
